<template>
  <div class="Dynamicmsg">
    <div class="head_banner">
      <div class="banner1">
        <h2>为客户提供全球跨境投资及争议解决</h2>
        <h2>一站式法律服务</h2>
        <div class="banner_tel" @click="callPhone('400-662-2729')">
          <img src="@/assets/bannertel.png" alt="" />
          400-662-2729
        </div>
      </div>
    </div>
    <div class="content" id="htmlContent">
      <div class="title"><i></i>盈科动态<span>YINGKE DYNAMIC</span></div>
      <div class="msg">
        <div class="back" @click="back">
          <img class="pc" src="@/assets/fanhui.png" alt="" />
          <img class="h5" src="@/assets/h5/back.png" alt="" />
          <div>返回动态列表</div>
        </div>
        <div class="article">
          <h4>
            {{ articleDeatl.title }}
          </h4>
          <div class="datas">
            <div>
              阅读人数：<span>{{ articleDeatl.hits }}</span>
            </div>
            <div>
              上传时间：<span>{{ articleDeatl.publishTime }}</span>
            </div>
          </div>
          <div class="neirong" v-html="articleDeatl.contents"></div>
        </div>
        <div class="next">
          <div class="left" @click="toDetails(prve.id)">
            上一篇：{{ prve.title }}
          </div>
          <div class="right" @click="toDetails(next.id)">
            下一篇：{{ next.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      articleDeatl: Object, //文章详情
      // id: 0,
      prve: {
        id: 0,
        title: "",
      },
      next: {
        id: 0,
        title: "",
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    this.$store.state.navChange = 5;
    this.getDetail();
    this.getPrveNext();
  },
  methods: {
    //获得文章详情
    getDetail() {
      this.axios({
        type: "get",
        url: this.Url.apiUrl + "Articles/Detail",
        params: {
          id: this.id,
        },
      }).then((res) => {
        this.articleDeatl = res.data;
      });
    },
    //加载上下篇
    getPrveNext() {
      let type = 1;
      let id = this.id;
      let order = this.$store.state.dynamicOrder;
      this.axios({
        type: "get",
        url: this.Url.apiUrl + "Articles/GetPreOrNext",
        params: {
          id: id,
          type: type,
          order: order,
        },
      }).then((res) => {
        this.prve.id = res.data.preID;
        this.prve.title = res.data.preTitle;
        this.next.id = res.data.nextID;
        this.next.title = res.data.nextTitle;
      });
    },
    //跳转文章详情
    toDetails(id) {
      if (id <= 0) {
        return false;
      }
      this.id = id;
      this.$router.push({
        name: "Dynamic-detail",
        params: {
          id: id,
        },
      });
      this.getDetail();
      this.getPrveNext();
      this.toTop();
    },
    //返回
    back() {
      this.$router.push({
        name: "Dynamic",
      });
    },
    toTop() {
      setTimeout(function () {
        let htmlContent = document.getElementById("htmlContent");
        htmlContent.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    },
  },
  mounted() {
    this.toTop();
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/Dynamicmsg.scss"
</style>